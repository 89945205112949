import { Link } from "react-router-dom";
import "./Together.css";

function Together() {
  return (
    <section className="Together_Container">
      <div>
        <img src="./imoge/smile.svg" alt="Smile_Imoge" />
        <h1>함께해요</h1>
        <h2>에너지 시장의 새로운 미래를 열어갈 당신을 기다립니다.</h2>
        <a
          href="https://blue-ocarina-b66.notion.site/994ec0493d0a4c9284529ee6fe932b79"
          target="_blank"
          style={{ textDecoration: "none", color: "#EC5B44" }}
        >
          <button>채용 바로 가기</button>
        </a>
      </div>
    </section>
  );
}

export default Together;
