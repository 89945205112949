import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import GA4React from "ga-4-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

try {
  setTimeout((_) => {
    const ga4react = new GA4React("G-2PJSECX6VW");
    ga4react.initialize().catch((err) => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}
