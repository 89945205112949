import { Link } from "react-router-dom";
import "./Footer.css";

function Footer({ setLinkScroll }) {
  return (
    <footer className="Footer_Container">
      <section className="Footer_List_Box">
        <div>
          <div>
            <h3>채용문의</h3>
            <Link
              to="#"
              onClick={(e) => {
                window.location = "mailto:hr@60hz.io";
                e.preventDefault();
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span>hr@60hz.io</span>
            </Link>
          </div>
          <div>
            <h3>기타문의</h3>
            <Link
              to="#"
              onClick={(e) => {
                window.location = "mailto:hello@60hz.io";
                e.preventDefault();
              }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <span>hello@60hz.io</span>
            </Link>
          </div>
        </div>
        <div>
          <div className="Footer_Infor_Box">
            <h3>식스티헤르츠</h3>
            <Link
              onClick={() => setLinkScroll(true)}
              to="/about"
              style={{
                textDecoration: "none",
                color: "black",
                textAlign: "left",
              }}
            >
              <span>ABOUT</span>
            </Link>
            <Link
              to="/business"
              onClick={() => setLinkScroll(true)}
              style={{
                textDecoration: "none",
                color: "black",
                textAlign: "left",
              }}
            >
              <span>BUSINESS</span>
            </Link>
            <a
              href="https://blue-ocarina-b66.notion.site/994ec0493d0a4c9284529ee6fe932b79"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "#000",
                textAlign: "left",
              }}
            >
              <span>CAREER</span>
            </a>
          </div>
        </div>
      </section>
      <section className="Footer_Company_Box">
        <div>
          <span style={{ fontFamily: "SUIT-SemiBold" }}>
            식스티헤르츠 주식회사
          </span>
          <span>&nbsp;|&nbsp; 대표: 김종규</span>
        </div>
        <div>
          <span>사업자등록번호: 878-88-01768</span>
        </div>
        <div>
          <span>전화번호: 02-6954-0068</span>
        </div>
        <div>
          <span>주소: (04768) 서울특별시 성동구 왕십리로 115, 703호</span>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
