import { Link } from "react-router-dom";
import "./Modal.css";

function Modal({ Modal_Hiden, handleModal, handleModalBool, handleMdCheck }) {
  const openClick = (value) => {
    handleModal("Modal_Close");
    handleModalBool(false);
    handleMdCheck(value);
  };

  return (
    <section className={`${Modal_Hiden} Modal_Container`}>
      <div className="Modal_Main_Box">
        <Link
          to="/"
          style={{ marginTop: "10px" }}
          onClick={() => openClick("home")}
        >
          <img src="./logo/60hz.svg" alt="60hz_Logo" />
        </Link>
        <img
          onClick={() => openClick()}
          src="./icon/close.svg"
          alt="Close_Icon"
        />
      </div>
      <ul className="Modal_List_Box">
        <a
          href="https://map.60hz.io/index.html"
          target="_blank"
          style={{ textDecoration: "none", color: "#000" }}
          onClick={() => openClick()}
        >
          <li>
            <div className="Modal_List_First">햇빛바람지도</div>
          </li>
        </a>
        <Link
          to="/about"
          style={{ textDecoration: "none", color: "black" }}
          onClick={() => openClick("Header_About")}
        >
          <li>ABOUT</li>
        </Link>
        <Link
          to="/business"
          style={{ textDecoration: "none", color: "black" }}
          onClick={() => openClick("Header_Business")}
        >
          <li>BUSINESS</li>
        </Link>
        <a
          href="https://blue-ocarina-b66.notion.site/994ec0493d0a4c9284529ee6fe932b79"
          target="_blank"
          style={{ textDecoration: "none", color: "#fff" }}
        >
          <li>CAREER</li>
        </a>
      </ul>
    </section>
  );
}

export default Modal;
